import styled from 'styled-components';
import { mediaMax } from '../../common/breakpoints';

export const Image = styled.img`
  float: left;
  width: 50%;
  max-width: 500px;
  ${mediaMax('small', `
    width: 100%;
  `)}
`;

export const NextToImageContainer = styled.div`
  float: left;
  box-sizing: border-box;
  padding: 17px 0 0 30px;
  width: 50%;
  clear: after;
  ${mediaMax('small', `
    width: 100%;
    padding-left: 0;
  `)}
  p, h2 {
    margin-top: 0;
  }
  ul {
    padding-left: 15px;
    margin: 0;
  }
`;

export const ImageAndTextContainer = styled.div`
  float: left;
  width: 100%;
`;

export const ResumeBlock = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }
`;

export const Headline = styled.h1`
  float: left;
  margin-bottom: 20px;
`;

export const Block = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 40px;
  h1, h2 {
    margin-top: 0;
  }
  ${mediaMax('small', `
    margin-bottom: 20px;
  `)}
`;

export const SingleLine = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 6px;
`;

export const ResumeTable = styled.table`
  width: 100%;
  font-size: 14px;
  line-height: 1.2em;
  border-spacing: 0;
  > tbody > tr > td:first-child {
    min-width: 90px;
  }
  ${mediaMax('medium', `
    font-size: 12px;
  `)}
  ${mediaMax('small', `
    font-size: 10px;
    > tbody > tr > td:first-child {
      min-width: 70px;
    }
  `)}
`;
