import React from 'react';
import { Block, Headline, Image, ImageAndTextContainer, NextToImageContainer, ResumeBlock, ResumeTable, SingleLine } from './styles';
import { PageContainer } from '../../styles';
import useDataStore from '../../services/DataService';
import { Link } from 'react-router-dom';

export default function Buro() {
  
  const data = useDataStore(state => state.buro);

  const renderResumes = ()=>{
    if (!data) return null;
    return data.resumes.map((r, i) => <ResumeBlock key={i}>
        <h2>{r.name}</h2>
        <ResumeTable>
          <tbody>
            {r.resumeItems.map((ri, i2) => <tr key={i2}><td>{ri.year}</td><td>{ri.text}</td></tr>)}
          </tbody>
        </ResumeTable>
      </ResumeBlock>);
  };

  if (!data) return <PageContainer />

  return (
    <PageContainer>
      <Block>
        <ImageAndTextContainer>
          <Image src={data.mainImg} />
          <NextToImageContainer dangerouslySetInnerHTML={{__html: data.contact}}></NextToImageContainer>
        </ImageAndTextContainer>
      </Block>
      <Block>
        {renderResumes()}
      </Block>
      <Block>
        <Headline>Team</Headline>
        <ImageAndTextContainer>
          <Image src={data.teamImg} />
          <NextToImageContainer dangerouslySetInnerHTML={{__html: data.team}}></NextToImageContainer>
        </ImageAndTextContainer>
      </Block>
      <Block lastOne>
        <Headline>Links</Headline>
        <SingleLine><Link to="/impressum">Impressum</Link></SingleLine>
        <SingleLine><Link to="/datenschutz">Datenschutzerklärung</Link></SingleLine>
      </Block>
    </PageContainer>
  );
}