import React, { useEffect, useMemo, useRef, useState } from 'react';
import useDataStore from '../../services/DataService';
import { useWindowStore } from '../../services/WindowService';
import { Container, NextProjectLink, ProjectInfoBody, ProjectInfoOverlay, Cross } from './styles';
import Project from '../Project';
import ReactFullpage from '@fullpage/react-fullpage';
import useNavigationStore from '../../services/NavigationService';
import { PageContainer } from '../../styles';
import { isLaterOrEqualThan } from '../../utilities/helpers';

export default function Projects() {
  const showProjectInfos = useNavigationStore(state => state.showProjectInfos);
  
  const [inTransition, setInTransition] = useState(false);
  const fullpageRef = useRef();
  const windowHeight = useWindowStore(state => state.windowHeight);
  const windowWidth = useWindowStore(state => state.windowWidth);
  const breakpoint = useWindowStore(state => state.breakpoint);
  const projects = useDataStore(state => state.projects);
  const sortedProjects = useMemo(()=>projects && projects.sort((a,b) => isLaterOrEqualThan(a.date, b.date) ? -1 : 1), [projects]);

  const renderProjects = () => {
    return sortedProjects.map((p,index) => <div className='section' data-anchor={p.id} key={index}><Project project={p} hideHUD={inTransition} width={windowWidth} height={windowHeight} breakpoint={breakpoint} /></div>)
  };

  useEffect(()=>{
    if (fullpageRef.current) fullpageRef.current.fullpageApi.setAllowScrolling(showProjectInfos === undefined);
  }, [showProjectInfos])

  const nextProject = ()=>{if(fullpageRef.current) fullpageRef.current.fullpageApi.moveSectionDown();
  };
  
  const onLeave = ()=>{ setInTransition(true) }; 
  const onArrive = ()=>{ setInTransition(false) }; 

  const disableCarousel = showProjectInfos !== undefined;

  return (
    <Container>
      {sortedProjects && <ReactFullpage
          licenseKey = {'8QL98-OK416-KEZ36-6C3JK-BPOVO'}
          continuousVertical
          scrollingSpeed = {800}
          ref = {fullpageRef}
          onLeave = {onLeave}
          lockAnchors = {false}
          credits={{enabled: false}}
          lazyLoading
          autoScrolling={!disableCarousel}
          animateAnchor={false}
          scrollOverflow={false}
          // scrollBar={true}
          css3={false}
          afterLoad = {(origin, destination, direction)=>{if(direction !== null) onArrive()}}
          render = {({ state, fullpageApi }) => {
            return (    
                <ReactFullpage.Wrapper>
                  {renderProjects()}
                </ReactFullpage.Wrapper>
            );
          }}
        />
      };
      {sortedProjects && <NextProjectLink onClick={nextProject}>nächstes projekt</NextProjectLink>}
      {showProjectInfos !== undefined && <ProjectInfoOverlay>
        <PageContainer>
          <h1>{showProjectInfos.title}</h1>
          <ProjectInfoBody dangerouslySetInnerHTML={{__html: showProjectInfos.description}} />
          <div>

          </div>
        </PageContainer>
        <Cross onClick={()=>{useNavigationStore.getState().setShowProjectInfos(undefined);}}>
          <div className='one' />
          <div className='two' />
        </Cross>
      </ProjectInfoOverlay>}
    </Container>
  );
}