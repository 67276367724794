import create from '../utilities/zustand/create';
import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL + '/api';
export const xApiKey = process.env.REACT_APP_X_API_SINGLE_JWT;

const username = 'appuser';
const pass = 'ECp5Wkch8pACd';

const useDataStore = create(module, (set, get) => ({
  jwt: undefined,
  logoUrl: undefined,
  projects: undefined,
  buro: undefined,
  authorize: () => {
    axios({
      method: 'post',
      url: API_URL + '/auth',
      headers: {
        'x-api-key': xApiKey,
        'authorization': 'Basic '+btoa(username + ':' + pass),
      }
      }).then(res => {
        if (res.status !== 200) {
          console.log(res);
          throw new Error('Could not authorize');
        } else {
            if (res.data.errors) console.log("Errors while authorizing: ", res.data.errors);
            // console.log('res.data', res.data);
            set({jwt: res.data.jwt});
        }
      });
    },
  getAllProjects: () => {
    const { jwt } = get();
    axios({
      headers: {
        'x-api-key': xApiKey,
        'authorization': 'Bearer '+jwt,
      },
      method: 'get',
      url: API_URL + '/projects',
      }).then(res => {
        // console.log('res.data', res.data);
        const logoUrl = res.data.logo;
        const projects = [...res.data.projects];
        const impressum = res.data.impressum;
        const datenschutz = res.data.datenschutz;
        const buro = res.data.buro;
        set({logoUrl, projects, impressum, datenschutz, buro});
      });
  },
}));

export default useDataStore;
