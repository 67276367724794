import styled, { keyframes } from 'styled-components';
import { breakPoints, vp } from '../../common/breakpoints';
import { colors, fadeIn, outerMargins } from '../../styles';

export const HeaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    padding: ${outerMargins.L}px;
    box-sizing: border-box;
    color: ${colors.darkGray};
    ${vp.small(`
      padding: ${outerMargins.S}px ${outerMargins.S}px 10px;
      background: white;
    `)}
    ${vp.medium(`
      padding: ${outerMargins.M}px;
    `)}
    a {
      color: inherit;
      text-decoration: none;
    }
`;

export const BurgerMenu = styled.div`
  position: absolute;
  right: ${outerMargins.S}px;
  top: ${outerMargins.S}px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  > div {
    width: 100%;
    margin-bottom: 4px;
    height: 2px;
    background-color: ${colors.midGray};
    transition: all ease-out 0.35s;
  }
  ${props => props.open && `
    > div.one {
      transform: translateY(6px) rotate(45deg);
    }
    > div.two {
      // transform: rotate(-45deg);
      opacity: 0;
    }
    > div.three {
      transform: translateY(-6px) rotate(-45deg);
    }
  `}
`;

export const Logo = styled.img`
    width: 200px;
    ${vp.small(`
      width: 160px;
    `)}
`;

export const MenuToggle = styled.div`
  animation: ${fadeIn} 0.3s linear forwards;
  float: right;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  float: right;
`;

const desktopFadeIn = keyframes`
  from {
    transform: translateX(5px);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`;


export const MenuItem = styled.div`
  opacity: 0;
  animation: ${desktopFadeIn} 0.2s ease-out ${props => 0.15-props.index * 0.03}s forwards;
  cursor: pointer;
  &:hover{
    color: ${colors.darkGray};
  }
  float: left;
  transition: color .1s cubic-bezier(0.42, 0, 0.58, 1);
  text-align: right;

  margin: 0 15px 0 0;
  &:last-of-type{
    margin: 0;
    animation: none;
    opacity: 1;
  }

  a {
    text-decoration: ${props => props.underline ? 'underline' : 'none'};
  } 

  @media only screen and (max-width: ${breakPoints['small'].max}px) {
    float: none;
    margin: 0 0 4px 0 !important;
  }
`;