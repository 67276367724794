import styled from 'styled-components';
import { centerVertically, colors } from '../../styles';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  a {
    text-decoration: none;
    color: ${colors.darkGray};
  }
`;

export const MenuContainer = styled.div`
  ${centerVertically};
  width: 100%;
  text-align: center;
  font-size: 22px;
`;

export const MenuItem = styled.div`
  text-decoration: ${props => props.underline ? "underline" : 'none'};
  padding: 12px 0;
  cursor: pointer;
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 22px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    >div {
      position: absolute;
      width: 3px;
      height: 100%;
      background: ${colors.darkGray};
      &.one { transform: translateX(11px) rotate(-45deg); }
      &.two { transform: translateX(11px) rotate(45deg); }
    }
`;
