import styled from 'styled-components';
import { absoluteFill, centered, colors, fadeIn, outerMargins } from '../../styles';
import { vp, mediaMax } from '../../common/breakpoints';

export const Container = styled.div`
  ${absoluteFill};
  .fp-watermark {
    display: none;
  }
`;

export const NextProjectLink = styled.a`
  position: absolute;
  font-weight: bold;
  cursor: pointer;
  right: 0;
  bottom: 0;
  padding: ${outerMargins.L}px;
  ${vp.small(`
    padding: ${outerMargins.S}px;
  `)}
  ${vp.medium(`
    padding: ${outerMargins.M}px;
  `)}
`;

export const ProjectInfoOverlay = styled.div`
  ${absoluteFill}
  background: rgba(255, 255, 255, 0.9);
  animation: ${fadeIn} 0.2s linear forwards;
  overflow-y: scroll;
  z-index: 3;
`;

export const ProjectInfoBody = styled.div`
`;

export const Cross = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  right: 0;
  margin: ${outerMargins.L}px;
  cursor: pointer;
  div {
    ${centered}
    width: 100%;
    height: 2px;
    background: ${colors.darkGray};
    &.one {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &.two {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  ${mediaMax('small', `
    width: 20px;
    height: 20px;
    margin: ${outerMargins.S}px;
  `)}
`;
