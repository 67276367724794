import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './App.css';
import AppContent from './components/AppContent';
import Header from './components/Header';
import useDataStore from './services/DataService';
import { useWindowStore } from './services/WindowService';
import { AppContainer, Loading } from './styles';

function App() {
  useEffect(()=>{
    document.title = "planrausch";
    useWindowStore.getState().init();
    useDataStore.getState().authorize();
  }, [])
  const jwt = useDataStore(state => state.jwt);

  useEffect(() => {
    if(jwt !== undefined) useDataStore.getState().getAllProjects();
  }, [jwt]);

  const projects = useDataStore(state => state.projects);

  if (projects === undefined) return <Loading>Lade Inhalte...</Loading>

  return (
    <Router>
      <div className='App'>
        <AppContainer>
          <Routes>
            <Route path='/' element={<Navigate to='/projekte' />} />
            <Route path='/:page' element={<><AppContent /><Header /></>}/>
          </Routes>
        </AppContainer>
      </div>
    </Router>
  );
}

export default App;
