import React from 'react';
import useDataStore from '../../services/DataService';
import { PageContainer } from '../../styles';

export default function SimplePage({page}) {

  const impressum = useDataStore(state => state.impressum);
  const datenschutz = useDataStore(state => state.datenschutz);

  return (
    <PageContainer>
      {page === 'impressum' && <div dangerouslySetInnerHTML={{__html: impressum}} />}
      {page === 'datenschutz' && <div dangerouslySetInnerHTML={{__html: datenschutz}} />}
    </PageContainer>
  );
}