import React, { useMemo, useRef, useState } from 'react';
import ReactSwipe from 'react-swipe';
import { Container, ShortDescription, LightLink, SliderContainer, SlideContainer, SliderImg, NextButton, PreviousButton, Pagination, PaginationDot, ArrowContainer, HUDcontainer } from './styles';
import ArrowImgSrc from '../../assets/images/arrow.png';
import useNavigationStore from '../../services/NavigationService';

export default function Project({project, hideHUD, width, height, breakpoint}) {
  const [imageIndex, setImageIndex] = useState(0);

  const reactSwipeEl = useRef();
  const onSlideChange = () => {
    setImageIndex(reactSwipeEl.current.getPos());
  };

  const swipeOptions = useRef({
    transitionEnd: onSlideChange,
    continuous: true,
    speed: 800
  });
  const previousSlide = () => { reactSwipeEl.current.prev() };
  const nextSlide = () => { reactSwipeEl.current.next() };

  const onPaginationClick = index => {
    reactSwipeEl.current.slide(index, 500);
    setImageIndex(index);
  }

  const showProjectInfos = project=>{
    useNavigationStore.getState().setShowProjectInfos(project);
  };

  // const slideHeight = isMobile ? Math.min(height*0.55, 180) : height*0.55;
  const slideHeight = useMemo(()=>{
    if (breakpoint === 'medium') {
      return height*0.44;
    } else if (breakpoint === 'large') {
      return height*0.55;
    } else {
      //small
      return Math.min(height*0.55, 240);
    }
  }, [breakpoint, height]);

  return (
    <Container>
      <SliderContainer>
        <ReactSwipe swipeOptions={swipeOptions.current} ref={reactSwipeEl} key={project.id}>
          {project.images.map((img, index) => 
            <SlideContainer key={index} style={{width: width, height: height}}>
              <SliderImg style={{backgroundImage: `url(${img})`, width: width-40, height: slideHeight}} />
            </SlideContainer>
          )}
        </ReactSwipe>
        {project.images.length > 1 && !hideHUD && (
          <Pagination key={project.id+"Pagination"}>
            {project.images.map((img, index) => <PaginationDot key={index} active={index === imageIndex} onClick={() => {onPaginationClick(index)}} />)}
          </Pagination>
        )}
      </SliderContainer>
      {!hideHUD && <ArrowContainer>
        <PreviousButton onClick={previousSlide}>
          <div className='arrow-container'> <img src={ArrowImgSrc} alt='previous arrow'/></div>
        </PreviousButton>
        <NextButton onClick={nextSlide}>
          <div className='arrow-container'> <img src={ArrowImgSrc} alt='next arrow'/></div>
        </NextButton>
      </ArrowContainer>}
      {!hideHUD && <HUDcontainer>
        <ShortDescription>
          <span>{project.title},</span> <span>{project.location}</span> <LightLink onClick={()=>{showProjectInfos(project)}}>mehr infos</LightLink>
        </ShortDescription>
      </HUDcontainer>}
    </Container>
  );
}