export const isLaterOrEqualThan = (date1, date2) => {
  const ss = [[3, 5], [0, 2]];
  for (let s = 0; s < ss.length; s++) {
      const from = ss[s][0], to = ss[s][1];
      const num1 = parseInt(date1.substring(from, to));
      const num2 = parseInt(date2.substring(from, to));
      if (num1 > num2) return true;
      if (num1 < num2) return false;
      if (s === ss.length-1 && num1 === num2) return true; // selber Monat/Jahr
  }
  return false;
}