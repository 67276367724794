import React from 'react';
import { Container, MenuContainer, MenuItem } from './styles';
import { Link, useParams } from 'react-router-dom';
import useNavigationStore from '../../services/NavigationService';

export default function MobileMenu() {

  const { page } = useParams();

  const CloseMenu = ()=>{useNavigationStore.getState().setMobileMenuOpen(false)};

  return (
    <Container>
      <MenuContainer>
        {[{label: "projekte", to: "projekte"}, {label: "büro", to: "buero"}].map((m, key)=>
          <MenuItem key={key} underline={page === m.to}>
            <Link to={"/" + m.to} onClick={CloseMenu}>
              {m.label}
            </Link>
          </MenuItem>
        )}
      </MenuContainer>
    </Container>
  );
}
