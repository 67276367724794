import styled from 'styled-components';
import { absoluteFill, centerVertically, centered, colors, outerMargins } from '../../styles';
import { fadeIn } from '../../styles';
import { vp } from '../../common/breakpoints';

export const Container = styled.div`
  ${absoluteFill};
`;

export const SliderContainer = styled.div`
  ${absoluteFill};
`;

export const Pagination = styled.div`
  animation: ${fadeIn} linear forwards 500ms;
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translateX(-50%);
  text-align: center;
  ${vp.small(`
    top: auto;
    bottom: 80px;
  `)}
`;

export const PaginationDot = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 1.5px 1.5px 0 1.5px;
  background-color: ${props => props.active ? colors.darkGray : colors.lightGray};
`;

const Button = styled.div`
  position: absolute;
  width: 30%;
  height: 100%;
  cursor: pointer;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      padding: ${outerMargins.L}px;
      /* ${vp.small(`
        width: 15px;
        padding: ${outerMargins.S}px;
      `)} */
      ${vp.medium(`
        padding: ${outerMargins.M}px;
      `)}
      opacity: 60%;
    }
  ${vp.small(`
    display: none;
  `)}
`;

export const PreviousButton = styled(Button)`
  left: 0;
  img {
    transform: translateY(-50%) scaleX(-1);
    left: 0;
  }
`;

export const NextButton = styled(Button)`
  right: 0;
  img {
    right: 0;
  }
`;


export const SlideContainer = styled.div`
  ${centerVertically};
`;

export const SliderImg = styled.div`
  ${centered}
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  ${vp.small(`
    // top: 52px;
    // transform: translateX(-50%);
  `)}
    /* background-color: red; */
`;

export const ShortDescription = styled.div`
  padding: ${outerMargins.L}px;
  ${vp.small(`
    max-width: 150px;
    padding: ${outerMargins.S}px;
  `)}
  ${vp.medium(`
    padding: ${outerMargins.M}px;
  `)}
`;

export const LightLink = styled.span`
  font-weight: bold;
  text-decoration: underline;
  color: ${colors.midGray};
  cursor: pointer;
`;

export const ArrowContainer = styled.div`
  animation: ${fadeIn} linear forwards 300ms;
`;

export const HUDcontainer = styled.div`
  position: absolute;
  display: inline-block;
  width: 100%;
  bottom: 0;
  left: 0;
  /* ${vp.small(`
    background: white;
  `)} */
  animation: ${fadeIn} linear forwards 300ms;
`;
