import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useDataStore from '../../services/DataService';
import { HeaderContainer, MenuContainer, MenuItem, Logo, BurgerMenu } from './styles';
import { useWindowStore } from '../../services/WindowService';
import useNavigationStore from '../../services/NavigationService';

export default function Header() {
  const logoUrl = useDataStore(state => state.logoUrl);
  const { page } = useParams();
  const isMobile = useWindowStore(state => state.isMobile);
  const mobileMenuOpen = useNavigationStore(state => state.mobileMenuOpen);
  
  const renderMenu = ()=> {
    if (isMobile) {
      return <BurgerMenu onClick={()=>{useNavigationStore.getState().setMobileMenuOpen(!mobileMenuOpen)}} open={mobileMenuOpen}>
          <div className='one'/><div className='two'/><div className='three'/>
        </BurgerMenu>;
    } else {
      return (
        <MenuContainer>
          {[{label: "projekte", to: "projekte"}, {label: "büro", to: "buero"}].map((m, i) => {
            return (
              <MenuItem underline={page === m.to} index={i} key={i}>
                <Link to={"/" + m.to}>
                  {m.label}
                </Link>
              </MenuItem>
            );
          })}
        </MenuContainer>
      );
    }
  };

  return (
    <HeaderContainer>
      <Link to='/'><Logo src={logoUrl} /></Link>
      {renderMenu()}
    </HeaderContainer>
  );
}
