import React from 'react';
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import Projects from '../Projects';
import Buro from '../Buro';
import SimplePage from '../SimplePage';
import useNavigationStore from '../../services/NavigationService';
import { useWindowStore } from '../../services/WindowService';
import MobileMenu from '../MobileMenu';

export default function AppContent() {

  const { page } = useParams();

  const mobileMenuOpen = useNavigationStore(state => state.mobileMenuOpen);
  const isMobile = useWindowStore(state => state.isMobile);

  return (
    <Container>
      {page === 'projekte' && <Projects key='Projects' />}
      {page === 'buero' && <Buro key='Buro' />}
      {(page === 'impressum' || page === 'datenschutz') && <SimplePage page={page} key='SimplePage'/>}
      {isMobile && mobileMenuOpen && <MobileMenu key='MobileMenu'/>}
    </Container>
  );
}