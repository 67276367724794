import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import AlbertSansMedium from './assets/fonts/AlbertSans-Medium.woff2';
import AlbertSansSemiBold from './assets/fonts/AlbertSans-Bold.woff2';
import { mediaMax, vp } from './common/breakpoints';

export const colors = {
  red: '#BD6951',
  midGray: '#919191',
  lightGray: '#bbb',
  darkGray: '#333',
};

export const outerMargins = {
  S: 20,
  M: 30,
  L: 40,
};

export const absoluteFill = css`
  position: absolute;
  inset: 0;
`;

export const centerVertically = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const centered = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const PageContainer = styled.div`
  width: 80%;
  height: 100%;
  box-sizing: border-box;
  max-width: 900px;
  margin: 0 auto 0;
  padding-top: 120px;
  ${mediaMax('medium', `
    padding-top: 100px;
  `)}
  ${mediaMax('small', `
    width: calc(100% - ${outerMargins.S * 2}px);
    padding-top: 60px;
    overflow-x: hidden;
    overflow-y: scroll;
  `)}
  > div:last-child {
    padding-bottom: 50px;
  }
`;

export const AppContainer = styled.div`
  ${absoluteFill};
  ${mediaMax('small', `
    overflow: hidden;
  `)}
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'AlbertSans';
    font-weight: normal;
    src: url('${AlbertSansMedium}') format('woff2');
  }
  @font-face {
    font-family: 'AlbertSans';
    font-weight: bold;
    src: url('${AlbertSansSemiBold}') format('woff2');
  }
  body {
    margin: 0;
    font-size: 16px;
    ${vp.small(`
      font-size: 14px;
    `)}
    overflow-x: hidden;
    font-family: 'AlbertSans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1 {
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 30px;
    ${mediaMax('small',`
      font-size: 20px;
      margin-bottom: 20px;
    `)}
  }
  h2 {
    font-size: 18px;
    font-weight: bold;
    ${mediaMax('small',`
      font-size: 14px;
    `)}
  }
  a {
    font-weight: bold;
    color: ${colors.darkGray};
  }
  /* h3 {
    font-size: 18px;
    font-weight: bold;
    ${mediaMax('small',`
      font-size: 14px;
    `)}
  } */
`;

export const Loading = styled.div`
  ${centerVertically}
  width: 100%;
  text-align: center;
`;

export const FullSize = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
