import create from '../utilities/zustand/create';

const useNavigationStore = create(module, (set, get) => ({
  showProjectInfos: undefined,
  mobileMenuOpen: false,
  setMobileMenuOpen: mobileMenuOpen => {
    set({mobileMenuOpen});
  },
  setShowProjectInfos: project => {
    set({showProjectInfos: project});
  },
}));

export default useNavigationStore;
