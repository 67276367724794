import create from '../utilities/zustand/create';
import { breakPoints } from '../common/breakpoints';
import debounce from '../utilities/debounce';
import { prefixEventListener } from '../utilities/prefixed-events';

export const useWindowStore = create(module, set => ({
  windowWidth: 0,
  windowHeight: 0,
  isMobile: false,
  breakpoint: undefined,

  init: () => {
    const onResize = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      let breakpoint = 'small';
      if (windowWidth > breakPoints.medium.min) {
        if (windowWidth > breakPoints.large.min) {
          breakpoint = 'large';
        } else {
          breakpoint = 'medium';
        }
      }
      set({ isMobile: windowWidth <= breakPoints.small.max, windowWidth, windowHeight, breakpoint });
    };

    prefixEventListener(window, 'resize', debounce(onResize, 250));
    onResize();
  },
}));
